import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import APIGuest from "../../apis/apiGuest";

export const getMemberProfile = createAsyncThunk(
	"members/getMemberProfile",
	async (rejectWithValue) => {
		try {
			const response = await API.get("/member/profile");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const deleteProfilePhoto = createAsyncThunk(
	"members/deleteProfilePhoto",
	async (params, { rejectWithValue, dispatch }) => {
		console.log("paramsparams", params);
		try {
			const response = await API.delete("/media/delete", {
				data: { media_cat: 1, delete_all: true },
			});
			dispatch(getMemberProfile());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const editMemberProfile = createAsyncThunk(
	"members/editMemberProfile",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.put("/member", params);
			dispatch(getMemberProfile());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const editMemberProfileF = createAsyncThunk(
	"members/editMemberProfileF",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.put("/member", params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.error.reason);
		}
	}
);

export const uploadProfilePhoto = createAsyncThunk(
	"members/uploadProfilePhoto",
	async (file, { rejectWithValue, dispatch }) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await API.post("/service/upload", formData);
			dispatch(getMemberProfile());
			dispatch(uploadMemberProfilePhoto(response.data.fullpath));
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const uploadMemberProfilePhoto = createAsyncThunk(
	"members/uploadMemberProfilePhoto",
	async (fullpath, { rejectWithValue, dispatch }) => {
		console.log("fullpath", fullpath);
		try {
			const response = await API.post("/member/profile/img", { fullpath });
			dispatch(getMemberProfile());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberQRCode = createAsyncThunk(
	"members/getMemberQRCode",
	async (rejectWithValue) => {
		try {
			const response = await API.get("/member/special/qrcode");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberFollowing = createAsyncThunk(
	"members/getMemberFollowing",
	async ({ signal }, rejectWithValue) => {
		try {
			const response = await API.get("/member/profile/followers", { signal });
			//  console.log(response.data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getFollowerFavorite = createAsyncThunk(
	"members/followerFavorite",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.post("follow/fans/starControl", params);
			dispatch(getMemberFollowing());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getUserFollowingStatus = createAsyncThunk(
	"members/getUserFollowingStatus",
	async ({ id, signal }, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.get(`/user/follow/${id}/status`, { signal });
			dispatch(getMemberFollowing({ signal }));
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getShopFollowingStatus = createAsyncThunk(
	"members/getShopFollowingStatus",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.get(`/shop/follow/${params}/status`);
			// dispatch(getMemberFollowing());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const followAndUnfollow = createAsyncThunk(
	"members/followAndUnfollow",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post("/user/follow/control", params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.error?.reason);
		}
	}
);
export const getMemberRealPhoto = createAsyncThunk(
	"members/getMemberRealPhoto",
	async (id, { rejectWithValue, dispatch }) => {
		try {
			const response = await APIGuest.get("/member/realPhoto/describe", id);
			dispatch(getMemberFollowing());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberClients = createAsyncThunk(
	"members/getMemberClients",
	async (params, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/member/clients`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getMemberClientFilters = createAsyncThunk(
	"members/getMemberClientFilters",
	async ({ data, signal }, rejectWithValue) => {
		// console.log("hh", data);
		try {
			const response = await APIGuest.post(`/member/clients/find2`, data, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getMemberClientFiltersV1 = createAsyncThunk(
	"members/getMemberClientFiltersV1",
	async ({ data, signal }, rejectWithValue) => {
		// console.log("hh", data);
		try {
			const response = await APIGuest.post(`/member/clients`, data, { signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getMemberRecommentationList = createAsyncThunk(
	"members/getMemberRecommentationList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/all-recommendation-list`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getMemberHotelGList = createAsyncThunk(
	"members/getMemberHotelGList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberForeignersList = createAsyncThunk(
	"members/getMemberForeignersList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberMassageList = createAsyncThunk(
	"members/getMemberMassageList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberWalkUpList = createAsyncThunk(
	"members/getMemberWalkUpList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberSaunaNightClubList = createAsyncThunk(
	"members/getMemberSaunaNightClubList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberKTVList = createAsyncThunk(
	"members/getMemberKTVList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getMemberMacauList = createAsyncThunk(
	"members/getMemberMacauList",
	async ({ params, signal }, rejectWithValue) => {
		try {
			const response = await APIGuest.post(`/member/clients/find2`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getClientRealPhoto = createAsyncThunk(
	"members/getClientRealPhoto",
	async ({ params, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/realPhoto/describe", {
				params,
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getClientADPhoto = createAsyncThunk(
	"members/getClientADPhoto",
	async (params, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/photos/ad", { params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getSpecialPhoto = createAsyncThunk(
	"members/getSpecialPhoto",
	async (params, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/special/catalogue", {
				params,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getClientADBanner = createAsyncThunk(
	"members/getClientADBanner",
	async (params, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/ADbanner/describe", {
				params,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getProductOneCoinSend = createAsyncThunk(
	"members/getProductOneCoinSend",
	async (qrKey, { rejectWithValue }) => {
		try {
			const response = await API.post(`/product/oneCoin/send`, {
				qr_key: qrKey,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);




// Get All the Member Client List 
// export const getMemberClientFilters = createAsyncThunk(
// 	"members/getMemberClientFilters",
// 	async ({ data, signal }, rejectWithValue) => {
// 		// console.log("hh", data);
// 		try {
// 			const response = await APIGuest.post(`/member/clients/find2`, data, {
// 				signal,
// 			});
// 			return response.data;
// 		} catch (error) {
// 			return rejectWithValue(error.response.data?.message);
// 		}
// 	}
// );