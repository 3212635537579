import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { APIGuest } from "../../apis/apiGuest";
import APIWithoutToken from "../../apis/apiWithoutToken";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const tokenGuest = createAsyncThunk(
  "home/tokenGuest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("/auth/guest");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data?.message);
    }
  }
);

export const getAllFlowerRanksHome = createAsyncThunk(
  "home/getAllFlowerRanks",
  async ({ params, signal }, { rejectWithValue }) => {
    try {
      // const response = await APIGuest.post(`home/top-flower-rankings-1`, params, {
      // 	...config,
      // 	signal,
      // });
      const response = await APIWithoutToken.post(
        `/flower/replica/rankList2`,
        params,
        {
          ...config,
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(error.message.data);
      }
    }
  }
);

export const getAllSpecialCataloguesHome = createAsyncThunk(
  "home/getAllSpecialCatalogues",
  async (params, rejectWithValue) => {
    try {
      // const response = await APIGuest.post("/specialCat/typeList", params);
      const response = await APIWithoutToken.post(
        "/specialCat/replica/typeList",
        params
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(error.message.data);
      }
    }
  }
);

export const getAllBannerHome = createAsyncThunk(
  "home/getAllBanner",
  async (signal, rejectWithValue) => {
    try {
      // const response = await APIGuest.get("/member/photos/banner", { signal });
      const response = await APIWithoutToken.get(
        "/member/replica/ADbanner/describe",
        { signal }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getAllMemberTopRecommendationHome = createAsyncThunk(
  "home/getAllMemberTopRecommendation",
  async ({ params, signal }, { rejectWithValue }) => {
    // console.log("Top resd", params);
    try {
      const response = await APIGuest.post(
        "home/top10-recommendation",
        params,
        {
          ...config,
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(error.message.data);
      }
    }
  }
);

export const getAllPostsHome = createAsyncThunk(
  "home/getAllPosts",
  async ({ params, signal }, rejectWithValue) => {
    // console.log(params);
    try {
      const response = await APIGuest.post("/member/home-posts", params, {
        ...config,
        signal,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(error.message.data);
      }
    }
  }
);

export const getAllShopsHome = createAsyncThunk(
  "home/getAllShops",
  async ({ params, signal }, rejectWithValue) => {
    // console.log("Shop Params ", params);
    try {
      const response = await APIWithoutToken.post(
        "/member/replica/shop/find",
        params,
        {
          ...config,
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(error.message.data);
      }
    }
  }
);

export const getAllPromotionHome = createAsyncThunk(
  "home/getAllPromotion",
  async (params, { rejectWithValue }) => {
    try {
      const response = await APIGuest.get(`/clientData/describe`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getAllPostReviewsHome = createAsyncThunk(
  "home/getAllPostReviews",
  async ({ params, signal }, { rejectWithValue }) => {
    // console.log("paramsparams", params);
    try {
      const response = await APIGuest.post("/member/instRev/list", {
        params,
        signal,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(error.message.data);
      }
    }
  }
);

export const getMemberHotelGListHome = createAsyncThunk(
  "home/getMemberHotelGListHome",
  async ({ params, signal }, rejectWithValue) => {
    try {
      const response = await APIGuest.post(`/all-recommendation-list`, params, {
        signal,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getAllClientTypesCount = createAsyncThunk(
  "home/getAllClientTypesCount",
  async ({ params, signal }, rejectWithValue) => {
    try {
      const response = await APIWithoutToken.post(`/get-client-counts`, params, {
        signal,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getAllRegion2Count = createAsyncThunk(
  "home/getAllRegion2Count",
  async ({ params, signal }, rejectWithValue) => {
    try {
      const response = await APIWithoutToken.post(
        `/get-region2-counts`,
        params,
        {
          signal,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getAllStreetAddressCount = createAsyncThunk(
  "home/getAllStreetAddressCount",
  async ({ params, signal }, rejectWithValue) => {
    try {
      const response = await APIWithoutToken.post(
        `/get-street-address-counts`,
        params,
        {
          signal,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getAllMemberData = createAsyncThunk(
  "home/getAllMemberData",
  async ({ params, signal }, rejectWithValue) => {
    try {
      const response = await APIGuest.post(`/all-recommendation-list`, params, {
        signal,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getMemberForeignersListHome = createAsyncThunk(
  "home/getMemberForeignersListHome",
  async ({ params, signal }, rejectWithValue) => {
    try {
      const response = await APIGuest.post(`/all-recommendation-list`, params, {
        signal,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);
