import { createAsyncThunk } from '@reduxjs/toolkit';
import APIGuest from '../../apis/apiGuest';

export const getAllCountries = createAsyncThunk('filters/getAllCountries', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get('/modules/country', params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllCurrency = createAsyncThunk('filters/getAllCurrency', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/currency`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllDisclaimer = createAsyncThunk('filters/getAllDisclaimer', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/disclaimer`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllFeature = createAsyncThunk('filters/getAllFeature', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/feature`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllLanguage = createAsyncThunk('filters/getAllLanguage', async (rejectWithValue) => {
	try {
		const response = await APIGuest.get(`/modules/language`);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getMemberPrice = createAsyncThunk('filters/getMemberPrice', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/member-price`, {
			params,
		});
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllNationality = createAsyncThunk('filters/getAllNationality', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/nationality`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllPromotion = createAsyncThunk('filters/getAllPromotion', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/promotion`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllRegion1 = createAsyncThunk('filters/getAllRegion1', async ({params, signal}, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/region1`, { params, signal });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllRegion2 = createAsyncThunk('filters/getAllRegion2', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/region2`, { params });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllRegion2Plus = createAsyncThunk('filters/getAllRegion2Plus', async ({params, signal}, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/region2Plus`, { params, signal });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllRegion2Plus2 = createAsyncThunk('filters/getAllRegion2Plus2', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/region2Plus2`, { params });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllRemarks = createAsyncThunk('filters/getAllRemarks', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/remarks`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllServiceType = createAsyncThunk('filters/getAllServiceType', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/service-type`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllServices = createAsyncThunk('filters/getAllServices', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/service?search=${params}`, params);
		console.log(params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllClientSpecialCat = createAsyncThunk('filters/getAllClientSpecialCat', async (signal, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/special-cat`, { signal });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllTypes = createAsyncThunk('filters/getAllTypes', async (signal, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/types`, { signal });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllTypesV2 = createAsyncThunk('filters/getAllTypesV2', async (signal, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/types-v2`);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllTypesByCountry = createAsyncThunk('filters/getAllTypesByCountry', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/types-by-country`, { params });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});
export const getAllTypesByCountryV2 = createAsyncThunk('filters/getAllTypesByCountry-v2', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/types-by-country-v2`, { params });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

export const getAllFilters = createAsyncThunk('filters/getAllFilters', async (params, { rejectWithValue }) => {
	try {
		const response = await APIGuest.get(`/modules/filters`, params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});