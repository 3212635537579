import { createSlice } from "@reduxjs/toolkit";

import { getAllShopMacau, getAllShops, getShopDetails } from "./actions";

const initialState = {
	shops: {},
	shopDetails: null,
	loading: "idle" | "pending" | "succeeded" | "failed",
	error: null,
	msg: "",
	lastPage: "",
	memberFollowingStatus: [],
	chinaClub: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	chinaKTV: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	hotelG: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	walkUp: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	massage: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	foreigners: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
		allTypes: {},
	},
	igbt: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
		items2: [],
		items3: [],
		items4: [],
		allTypes: {},
	},
	newGirls: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	shopList: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	instantPostAll: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	currentWeekWinner: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
		currentWeekWinner: [],
		othersWeekWinner: [],
	},
	lastWeekWinner: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
		lastWeekWinner: [],
		othersWeekWinner: [],
	},
};

export const shopsSlice = createSlice({
	name: "shops",
	initialState,
	reducers: {
		setShopLastPage: (state, action) => {
			state.lastPage = action.payload;
		},
		setShopDetailEmpty: (state) => {
			state.shopDetails = null;
		},
		setChinaClubLastData: (state, action) => {
			console.log("Action payload:", action.payload);
			state.chinaClub.currentPage = action.payload.currentPage;
			state.chinaClub.totalPages = action.payload.totalPages;
			state.chinaClub.totalItems = action.payload.totalItems;
			state.chinaClub.items = action.payload.filteredNewArray;
		},
		setChinaKTVLastData: (state, action) => {
			state.chinaKTV.currentPage = action.payload.currentPage;
			state.chinaKTV.totalPages = action.payload.totalPages;
			state.chinaKTV.totalItems = action.payload.totalItems;
			state.chinaKTV.items = action.payload.filteredNewArray;
		},
		setHotelGLastData: (state, action) => {
			state.hotelG.currentPage = action.payload.currentPage;
			state.hotelG.totalPages = action.payload.totalPages;
			state.hotelG.totalItems = action.payload.totalItems;
			state.hotelG.items = action.payload.filteredNewArray;
		},
		setWalkUpData: (state, action) => {
			state.hotelG.currentPage = action.payload.currentPage;
			state.hotelG.totalPages = action.payload.totalPages;
			state.hotelG.totalItems = action.payload.totalItems;
			state.hotelG.items = action.payload.filteredNewArray;
		},
		setMassageData: (state, action) => {
			state.hotelG.currentPage = action.payload.currentPage;
			state.hotelG.totalPages = action.payload.totalPages;
			state.hotelG.totalItems = action.payload.totalItems;
			state.hotelG.items = action.payload.filteredNewArray;
		},
		setForeignerLastData: (state, action) => {
			state.foreigners.currentPage = action.payload.currentPage;
			state.foreigners.totalPages = action.payload.totalPages;
			state.foreigners.totalItems = action.payload.totalItems;
			state.foreigners.items = action.payload.filteredNewArray;
			state.foreigners.allTypes = action.payload.allTypes;
		},
		setIGBTLastData: (state, action) => {
			state.igbt.currentPage = action.payload.currentPage;
			state.igbt.totalPages = action.payload.totalPages;
			state.igbt.totalItems = action.payload.totalItems;
			state.igbt.items = action.payload.filteredNewArray;
		
			state.igbt.allTypes = action.payload.allTypes;
		},
		setNewGirlsLastData: (state, action) => {
			state.newGirls.currentPage = action.payload.currentPage;
			state.newGirls.totalPages = action.payload.totalPages;
			state.newGirls.totalItems = action.payload.totalItems;
			state.newGirls.items = action.payload.filteredNewArray;
		},
		setShopListLastData: (state, action) => {
			state.shopList.currentPage = action.payload.currentPage;
			state.shopList.totalPages = action.payload.totalPages;
			state.shopList.totalItems = action.payload.totalItems;
			state.shopList.items = action.payload.newShopsArray;
		},
		setInstantPostAllLastData: (state, action) => {
			state.instantPostAll.currentPage = action.payload.currentPage;
			state.instantPostAll.totalPages = action.payload.totalPages;
			state.instantPostAll.totalItems = action.payload.totalItems;
			state.instantPostAll.items = action.payload.items;
		},
		setCurrentWeekWinnerData: (state, action) => {
			state.currentWeekWinner.currentPage = action.payload.currentPage;
			state.currentWeekWinner.totalPages = action.payload.totalPages;
			state.currentWeekWinner.totalItems = action.payload.totalItems;
			state.currentWeekWinner.items = action.payload.items;
			state.currentWeekWinner.currentWeekWinner = action.payload.currentWeekWinner;
			state.currentWeekWinner.othersWeekWinner = action.payload.othersWeekWinner;
		},
		setlastWeekWinnerData: (state, action) => {
			state.lastWeekWinner.currentPage = action.payload.currentPage;
			state.lastWeekWinner.totalPages = action.payload.totalPages;
			state.lastWeekWinner.totalItems = action.payload.totalItems;
			state.lastWeekWinner.items = action.payload.items;
			state.lastWeekWinner.lastWeekWinner = action.payload.lastWeekWinner;
			state.lastWeekWinner.othersWeekWinner = action.payload.othersWeekWinner;
		},
		setMemberFollowingStatus: (state, action) => {
			state.memberFollowingStatus = action.payload;
		},
		updateMemberFollowingStatus: (state, action) => {
			const { shop_id, followingStatus } = action.payload;
			const index = state.memberFollowingStatus.findIndex((items) => items.shop_id === shop_id);
			if (index !== -1) {
				state.memberFollowingStatus[index].followingStatus = followingStatus;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllShops.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getAllShops.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.shops = action.payload;
				state.msg = "Special Catalogue data successfully fetched ";
			})
			.addCase(getAllShops.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getAllShopMacau.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getAllShopMacau.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.shops = action.payload;
				state.msg = "Special Catalogue data successfully fetched ";
			})
			.addCase(getAllShopMacau.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getShopDetails.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getShopDetails.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.shopDetails = action.payload;
				state.msg = "Special Catalogue data successfully fetched ";
			})
			.addCase(getShopDetails.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			});
	},
});

export const {
	setShopLastPage,
	setShopDetailEmpty,
	setChinaClubLastData,
	setChinaKTVLastData,
	setHotelGLastData,
	setMassageData,
	setWalkUpData,
	setForeignerLastData,
	setIGBTLastData,
	setNewGirlsLastData,
	setShopListLastData,
	setInstantPostAllLastData,
	setMemberFollowingStatus,
	updateMemberFollowingStatus,
	setlastWeekWinnerData,
	setCurrentWeekWinnerData,
} = shopsSlice.actions;
export default shopsSlice.reducer;
