import { createSlice } from "@reduxjs/toolkit";
import { getMemberClientFilters } from "../member/actions";
import { getAdBlogDetails } from "../flower/actions";

const initialState = {
  allClients: [],
  activeClient: null,
  nextClient: null,
  preClient: null,
  viewedClient: null,
  currentClient: null,
  loading: "idle",
  error: null,
  msg: "",
};

const allallClientsSlice = createSlice({
  name: "clientDatas",
  initialState,
  reducers: {
    setAllallClients: (state, action) => {
      state.allClients = action.payload;
    },
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload;
    },
    setNextClient: (state, action) => {
      state.nextClient = action.payload;
    },
    setPreClient: (state, action) => {
      state.preClient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberClientFilters.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMemberClientFilters.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.allClients = action.payload["item(s)"];
        state.msg = "";
      })
      .addCase(getMemberClientFilters.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
        state.msg = action.error.message;
      })
      .addCase(getAdBlogDetails.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAdBlogDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.details = action.payload;  
      })
      .addCase(getAdBlogDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setAllallClients, setCurrentClient, setNextClient, setPreClient } = allallClientsSlice.actions;
export default allallClientsSlice.reducer;
